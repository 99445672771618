import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

const NotFoundPage = ({ location }) => {
  return (
    <Layout contentClassName="layout-content" location={location}>
      <h1>Сторінку не знайдено</h1>
      <p>
        <Link to="/">Вернутися на сайт</Link>
      </p>
    </Layout>
  )
}

export default NotFoundPage
